import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pricing-calculator"

// Controller for updating the pricing component. This has 5 different inputs, each of which
// triggers the calculateCosts function. This works by getting the values of each input, establishing
// a base cost by checking the stored costMatrix. Then it proceeds to add the recruit and advice values
// if those have been checked. Finally the value for the total cost is updated in the view
export default class extends Controller {
  #costMatrix = {
    one: {
      charlie: 5,
      charliePlus: 30,
      charlieAdvice: 179,
      charlieRecruit: 55,
    },
    five: {
      charlie: 40,
      charliePlus: 60,
      charlieAdvice: 179,
      charlieRecruit: 55,
    },
    ten: {
      charlie: 40,
      charliePlus: 60,
      charlieAdvice: 299,
      charlieRecruit: 55,
    },
    fifteen: {
      charlie: 100,
      charliePlus: 120,
      charlieAdvice: 299,
      charlieRecruit: 85,
    },
    thirty: {
      charlie: 170,
      charliePlus: 200,
      charlieAdvice: 299,
      charlieRecruit: 85,
    },
    fifty: {
      charlie: 300,
      charliePlus: 360,
      charlieAdvice: 299,
      charlieRecruit: 100,
    },
    one_hundred: {
      charlie: 400,
      charliePlus: 480,
      charlieAdvice: 499,
      charlieRecruit: 100,
    },
    hundred_and_fifty: {
      charlie: 500,
      charliePlus: 600,
      charlieAdvice: 499,
      charlieRecruit: 100,
    },
    two_hundred: {
      charlie: 600,
      charliePlus: 720,
      charlieAdvice: 499,
      charlieRecruit: 150,
    },
    two_hundred_and_fifty: {
      charlie: 700,
      charliePlus: 840,
      charlieAdvice: 499,
      charlieRecruit: 150,
    },
  };
  #shadowRoot = document.querySelector('#ento-part-pricing').shadowRoot;
  #teamMembers = this.#shadowRoot.querySelector("#team_member_count").value
  #totalStandardCost = this.#costMatrix[this.#teamMembers]["charlie"]
  #totalPlusCost = this.#costMatrix[this.#teamMembers]["charliePlus"]

  calculateCosts() {
    this.#teamMembers = this.#shadowRoot.querySelector("#team_member_count").value

    // Base cost
    this.#totalStandardCost = this.#costMatrix[this.#teamMembers]["charlie"]
    this.#totalPlusCost = this.#costMatrix[this.#teamMembers]["charliePlus"]

    // Additional costs
    this.addCostIfChecked("standard", "charlieRecruit", "#charlie-standard-recruit")
    this.addCostIfChecked("standard", "charlieAdvice", "#charlie-standard-advice");
    this.addCostIfChecked("plus", "charlieRecruit", "#charlie-plus-recruit");
    this.addCostIfChecked("plus", "charlieAdvice", "#charlie-plus-advice");


    this.updateCosts();
  }

  updateCosts() {
    const charlieStandardText = this.#shadowRoot.querySelector("#charlie-standard-cost")
    charlieStandardText.textContent = `£${this.#totalStandardCost}`
    const charliePlusText = this.#shadowRoot.querySelector("#charlie-plus-cost")
    charliePlusText.textContent = `£${this.#totalPlusCost}`
  }

  addCostIfChecked(costToUpdate, costType, costProperty) {
    const elementToCheck = this.#shadowRoot.querySelector(costProperty).checked;

    if (elementToCheck === true && costToUpdate === "standard") {
      this.#totalStandardCost += this.#costMatrix[this.#teamMembers][costType];
    } else if (elementToCheck === true && costToUpdate === "plus") {
      this.#totalPlusCost += this.#costMatrix[this.#teamMembers][costType];
    }
  }
}