import { Application, Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'
import { debounceAsync } from '../modules/debounce-async';
import DesignSystemInputErrors from '/app/components/input/js/input-errors.js';
import ButtonController from "../../components/button/button_controller"

// Connects to data-controller="shadow-form"
export default class extends Controller {
  static targets = ['container', 'template']
  #movedToShadow;

  initialize() {
    this.#movedToShadow = false;
    this.boundHandleSubmit = this.handleSubmit.bind(this);
    this.debouncedBoundHandleInputtingName = debounceAsync(this.handleInputtingName.bind(this), 250);
    this.boundHandleInputtingName = this.handleInputtingName.bind(this);
    this.boundHandleInput = this.handleInput.bind(this);
    this.moveFragmentToShadow();
    this.initializeVWO();
  }

  connect() {
    this.element[this.identifier] = this;
    this.moveFragmentToShadow();
    this.enableButton(this.form.querySelector('button[type="submit"]'));
  }

  disconnect() {
    this.#movedToShadow = false;
  }

  moveFragmentToShadow() {
    if (this.#movedToShadow) {
      return;
    }
    this.#movedToShadow = true;
    const { containerTarget } = this;
    const shadowRoot = containerTarget.attachShadow({ mode: 'open' });
    const templateContent = this.templateTarget.content.childNodes;
    shadowRoot.append(...templateContent);
    this.form = shadowRoot.querySelector('#new_sign_up');
    this.nameInput = this.form.querySelector('input[name="sign_up[name]"]');
    this.subdomainInput = this.form.querySelector('input[name="sign_up[subdomain]"]');
    this.inputsWithError = this.form.querySelectorAll('input.has-error');
    this.shadowApp = Application.start(this.form);
    this.shadowApp.register("button", ButtonController);
  }

  scrollToForm() {
    document.getElementById('sign-up-form').scrollIntoView();
  }

  containerTargetConnected(element) {
    this.form.addEventListener('submit', this.boundHandleSubmit);
    this.nameInput.addEventListener('keyup', this.debouncedBoundHandleInputtingName);
    this.nameInput.addEventListener('change', this.boundHandleInputtingName);
    this.inputsWithError.forEach((input) => {
      $(input).data('input', new DesignSystemInputErrors($(input)));
      input.addEventListener('change', this.boundHandleInput, { once: true });
    });
  }

  containerTargetDisconnected(element) {
    this.form.removeEventListener('submit', this.boundHandleSubmit);
    this.nameInput.removeEventListener('keyup', this.debouncedBoundHandleInputtingName);
    this.nameInput.removeEventListener('change', this.boundHandleInputtingName);
    this.inputsWithError.forEach((input) => {
      input.removeEventListener('change', this.boundHandleInput);
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { target: form, submitter } = event;

    this.disableButton(submitter);

    try {
      const response = await this.sendFormData(form);
      if (response.ok) {
        this.handleVWOCampaign();
      }
      if (response.statusCode === 422) {
        await response.renderTurboStream();
      }
    } catch (error) {
      console.error(`Error while handling submit: ${error}`);
    }
  }

  handleVWOCampaign() {
    window.VWO.event("freeTrialConversion");
  }

  disableButton(button) {
    if (!button) return;

    const buttonInstance = this.parseButtonDataAttributes(button);
    (buttonInstance?.disable?.() || (button.disabled = true));
  }

  enableButton(button) {
    if (!button) return;

    const buttonInstance = this.parseButtonDataAttributes(button);
    (buttonInstance?.enable?.() || (button.disabled = false));
  }

  parseButtonDataAttributes(button) {
    JSON.parse(button.dataset.button ?? "null");
  }

  async sendFormData(form) {
    const formData = new FormData(form);
    const postOptions = {
      body: formData,
      responseKind: "turbo-stream"
    }
    return await post(form.action, postOptions);
  }

  handleInputtingName(event) {
    this.autofillSubdomain();
  }

  async autofillSubdomain() {
    const companyName = this.nameInput.value;
    if (!companyName) return;
    const response = await this.fetchAvailableSubdomain(companyName);
    try {
      const data = await response.json;
      this.setSubdomain(data.subdomain);
    } catch (error) {
      console.error(`Error setting subdomain: ${error}`);
      this.clearSubdomain();
    }
  }

  async fetchAvailableSubdomain(companyName) {
    const endpoint = this.nameInput.dataset.availableSubdomainEndpoint;
    const subdomainParams = { company_name: companyName };
    const getOptions = { query: new URLSearchParams(subdomainParams), contentType: 'application/json', responseKind: 'json' }
    return await get(endpoint, getOptions);
  }

  clearSubdomain() {
    this.subdomainInput.value = '';
  }

  setSubdomain(subdomain) {
    this.subdomainInput.value = subdomain;
    this.subdomainInput.dispatchEvent(new Event('change'));
  }

  handleInput(event) {
    const input = event.currentTarget;
    if (input.classList.contains('has-error')) {
      $(input).data('input').removeError();
      // #removeError() only recognises errors added by #setError().
      input.parentElement.querySelector('.js-input_error').remove();
    }
  }

  initializeVWO() {
    window.VWO = window.VWO || [];
    window.VWO.event = window.VWO.event || function () {window.VWO.push(["event"].concat([].slice.call(arguments)))};
  }
}
