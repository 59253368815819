import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="calendly-embed"
export default class extends Controller {
  initialize() {
  }

  connect() {
    // We already have a pre-existing VWO event "demoBooked" on the demo page so just want to add
    // a blank string to "demoBooked" in that case to still hit the right event
    const pageName = this.element.dataset.pageName === "General" ? "" : this.element.dataset.pageName;

    window.addEventListener('message', (e) => {
      if (this.isCalendlyBookingEvent(e)) {
        const payload = this.fetchEventPayload(e)
          .then(response => response.json)
          .then(booking => {
            // VWO event completed
            window.VWO = window.VWO || [];
            VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};

            VWO.event(`demoBooked${pageName}`, {
              "meetingUrlPath": booking['url_path'],
              "pageUrlPath": window.location.pathname,
            });
          });
      }
    });
  }

  isCalendlyBookingEvent(e) {
    return e.data.event &&
           e.data.event == 'calendly.event_scheduled';
  };

  async fetchEventPayload(e) {
    const endpoint = '/calendly/booking';
    const params = { uri: e.data.payload.event.uri }
    const options = { query: new URLSearchParams(params), contentType: 'application/json', responseKind: 'json',  }
    return await get(endpoint, options)
  }
}
